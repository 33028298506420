define("@glimmer/component/-private/destroyables", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setDestroying = setDestroying;
  _exports.setDestroyed = setDestroyed;
  _exports.isDestroying = isDestroying;
  _exports.isDestroyed = isDestroyed;
  var DESTROYING = new WeakMap();
  var DESTROYED = new WeakMap(); // TODO: remove once glimmer.js is updated to glimmer-vm 0.54.0+ and can use the destroyables API directly

  function setDestroying(component) {
    DESTROYING.set(component, true);
  }

  function setDestroyed(component) {
    DESTROYED.set(component, true);
  }

  function isDestroying(component) {
    return DESTROYING.has(component);
  }

  function isDestroyed(component) {
    return DESTROYED.has(component);
  }
});