define("ember-ref-bucket/index", ["exports", "ember-ref-bucket/utils/ref"], function (_exports, _ref) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ref = ref;
  _exports.globalRef = globalRef;
  _exports.trackedRef = trackedRef;
  _exports.trackedGlobalRef = trackedGlobalRef;
  Object.defineProperty(_exports, "registerNodeDestructor", {
    enumerable: true,
    get: function get() {
      return _ref.registerNodeDestructor;
    }
  });
  Object.defineProperty(_exports, "unregisterNodeDestructor", {
    enumerable: true,
    get: function get() {
      return _ref.unregisterNodeDestructor;
    }
  });

  function maybeReturnCreated(value, createdValues, fn, ctx) {
    if (value === null || value === undefined) {
      return null;
    }

    if (fn) {
      if (!createdValues.has(value)) {
        createdValues.set(value, fn.call(ctx, value));
      }

      return createdValues.get(value);
    } else {
      return value;
    }
  }

  function ref(name, fn) {
    return function () {
      var createdValues = new WeakMap();
      return {
        get: function get() {
          var value = (0, _ref.bucketFor)(this).get(name);
          return maybeReturnCreated(value, createdValues, fn, this);
        }
      };
    };
  }

  function globalRef(name, fn) {
    return function () {
      var createdValues = new WeakMap();
      return {
        get: function get() {
          var value = (0, _ref.bucketFor)(Ember.getOwner(this) || (0, _ref.resolveGlobalRef)()).get(name);
          return maybeReturnCreated(value, createdValues, fn, this);
        }
      };
    };
  }

  function trackedRef(name, fn) {
    return function () {
      var createdValues = new WeakMap();
      return {
        get: function get() {
          var value = (0, _ref.bucketFor)(this).getTracked(name);
          return maybeReturnCreated(value, createdValues, fn, this);
        }
      };
    };
  }

  function trackedGlobalRef(name, fn) {
    return function () {
      var createdValues = new WeakMap();
      return {
        get: function get() {
          var value = (0, _ref.bucketFor)(Ember.getOwner(this) || (0, _ref.resolveGlobalRef)()).getTracked(name);
          return maybeReturnCreated(value, createdValues, fn, this);
        }
      };
    };
  }
});