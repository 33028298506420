define('ember-prop-types/utils/validators/symbol', ['exports', 'ember', 'ember-prop-types/utils/logger'], function (exports, _ember, _emberPropTypesUtilsLogger) {
  var typeOf = _ember['default'].typeOf;

  exports['default'] = function (ctx, name, value, def, logErrors, throwErrors) {
    var valid = typeof value === 'symbol';

    if (!valid && logErrors) {
      _emberPropTypesUtilsLogger['default'].warn(ctx, 'Expected property ' + name + ' to be a symbol but instead got: ' + typeOf(value), throwErrors);
    }

    return valid;
  };
});
/**
 * The PropTypes.symbol validator
 */