define("ember-data-storefront/-private/record-array-query", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var RecordArrayQuery = function () {
    function RecordArrayQuery(store, type, params) {
      _classCallCheck(this, RecordArrayQuery);

      this.store = store;
      this.type = type;
      this.params = params;

      this.value = null;
    }

    _createClass(RecordArrayQuery, [{
      key: "run",
      value: function run() {
        var _this = this;

        var promise = void 0;

        if (this.value) {
          promise = this.value.update();
        } else {
          promise = this.store.query(this.type, this.params).then(function (records) {
            _this.value = records;

            return records;
          });
        }

        return promise;
      }
    }]);

    return RecordArrayQuery;
  }();

  exports.default = RecordArrayQuery;
});