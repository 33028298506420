define('ember-prop-types/utils/validators/one-of', ['exports', 'ember', 'ember-prop-types/utils/logger'], function (exports, _ember, _emberPropTypesUtilsLogger) {
  var typeOf = _ember['default'].typeOf;

  exports['default'] = function (ctx, name, value, def, logErrors, throwErrors) {
    var valueOptions = def.valueOptions;

    if (typeOf(valueOptions) !== 'array') {
      _emberPropTypesUtilsLogger['default'].warn(ctx, 'PropTypes.oneOf() requires an array of values to be passed in as an argument', throwErrors);
      return false;
    }

    var valid = valueOptions.some(function (option) {
      return option === value;
    });

    if (!valid && logErrors) {
      _emberPropTypesUtilsLogger['default'].warn(ctx, 'Property ' + name + ' is not one of: ' + valueOptions.join(', '), throwErrors);
    }

    return valid;
  };
});
/**
 * The PropTypes.oneOf validator
 */