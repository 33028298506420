define('ember-data-storefront/-private/coordinator', ['exports', 'ember-data-storefront/-private/cache', 'ember-data-storefront/-private/record-query', 'ember-data-storefront/-private/record-array-query'], function (exports, _cache, _recordQuery, _recordArrayQuery) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var Coordinator = function () {
    function Coordinator(store) {
      _classCallCheck(this, Coordinator);

      this.store = store;
      this.recordCache = new _cache.default();
      this.arrayCache = new _cache.default();

      // A materialized view of loaded includes from the cache's queries.
      this.loadedIncludes = {};
    }

    _createClass(Coordinator, [{
      key: 'recordQueryFor',
      value: function recordQueryFor(type, id, params) {
        var query = this.recordCache.get(type, id, params);

        if (!query) {
          query = this._assembleRecordQuery(type, id, params);
          this._rememberRecordQuery(query);
        }

        return query;
      }
    }, {
      key: 'recordArrayQueryFor',
      value: function recordArrayQueryFor(type, params) {
        var query = this.arrayCache.get(type, params);

        if (!query) {
          query = this._assembleRecordArrayQuery(type, params);
          this._rememberRecordArrayQuery(query);
        }

        return query;
      }
    }, {
      key: 'queryFor',
      value: function queryFor() {
        return arguments.length === 3 ? this.recordQueryFor.apply(this, arguments) : this.recordArrayQueryFor.apply(this, arguments);
      }
    }, {
      key: 'dump',
      value: function dump() {
        var records = this.recordCache.all();
        var arrays = this.arrayCache.all();

        return records.concat(arrays);
      }
    }, {
      key: 'recordHasIncludes',
      value: function recordHasIncludes(type, id, includesString) {
        var query = this._assembleRecordQuery(type, id, { include: includesString });
        var nonLoadedIncludes = this._nonLoadedIncludesForQuery(query);

        return nonLoadedIncludes.length === 0;
      }
    }, {
      key: '_assembleRecordQuery',
      value: function _assembleRecordQuery(type, id, params) {
        var query = new _recordQuery.default(this.store, type, id, params);

        if (this._queryValueCanBeDerived(query)) {
          query.value = this.store.peekRecord(type, id);
        }

        return query;
      }
    }, {
      key: '_assembleRecordArrayQuery',
      value: function _assembleRecordArrayQuery(type, params) {
        var query = new _recordArrayQuery.default(this.store, type, params);

        return query;
      }
    }, {
      key: '_queryValueCanBeDerived',
      value: function _queryValueCanBeDerived(query) {
        var queryKeys = Object.keys(query.params);
        if (queryKeys.length === 1 && queryKeys[0] === 'include') {
          var nonLoadedIncludes = this._nonLoadedIncludesForQuery(query);

          return nonLoadedIncludes.length === 0;
        }
      }
    }, {
      key: '_nonLoadedIncludesForQuery',
      value: function _nonLoadedIncludesForQuery(query) {
        var loadedIncludes = Ember.get(this, 'loadedIncludes.' + query.type + '.' + query.id) || [];
        var includesString = query.params.include || '';

        return includesString.split(',').filter(function (include) {
          return !!include;
        }).filter(function (include) {
          return !loadedIncludes.find(function (loadedInclude) {
            return loadedInclude.indexOf(include) === 0;
          });
        });
      }
    }, {
      key: '_rememberRecordQuery',
      value: function _rememberRecordQuery(query) {
        this.recordCache.put(query);
        this._updateLoadedIncludesWithQuery(query);
      }
    }, {
      key: '_rememberRecordArrayQuery',
      value: function _rememberRecordArrayQuery(query) {
        this.arrayCache.put(query);
      }
    }, {
      key: '_updateLoadedIncludesWithQuery',
      value: function _updateLoadedIncludesWithQuery(query) {
        this.loadedIncludes[query.type] = this.loadedIncludes[query.type] || {};
        this.loadedIncludes[query.type][query.id] = this.loadedIncludes[query.type][query.id] || [];

        var currentIncludes = this.loadedIncludes[query.type][query.id];
        var nonLoadedIncludes = this._nonLoadedIncludesForQuery(query);
        var newLoadedIncludes = [].concat(_toConsumableArray(currentIncludes), _toConsumableArray(nonLoadedIncludes));

        this.loadedIncludes[query.type][query.id] = newLoadedIncludes;
      }
    }]);

    return Coordinator;
  }();

  exports.default = Coordinator;
});