define('ember-data-storefront/-private/utils/get-key', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var _serializeParams = function _serializeParams() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var prefix = arguments[1];

    var query = Object.keys(params).sort().map(function (key) {
      var value = params[key];

      if (params.constructor === Array) {
        key = prefix + '[]';
      } else if (params.constructor === Object) {
        key = prefix ? prefix + '[' + key + ']' : key;
      }

      if ((typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'object' && value !== null) {
        return _serializeParams(value, key);
      } else {
        return key + '=' + encodeURIComponent(value);
      }
    });

    return [].concat.apply([], query).join('&');
  };

  var serializeObject = function serializeObject(params) {
    return _serializeParams(params);
  };

  var queryCacheKey = function queryCacheKey(query) {
    return cacheKey([query.type, query.id, query.params]);
  };

  var cacheKey = function cacheKey(args) {
    return args.map(function (part) {
      return (typeof part === 'undefined' ? 'undefined' : _typeof(part)) === "object" ? serializeObject(part) : part;
    }).filter(function (part) {
      return !!part;
    }).join('::');
  };

  var shoeboxize = function shoeboxize(key) {
    return key.replace(/&/g, '--'); // IDGAF
  };

  exports.serializeObject = serializeObject;
  exports.queryCacheKey = queryCacheKey;
  exports.cacheKey = cacheKey;
  exports.shoeboxize = shoeboxize;
});