define('ember-data-storefront/mixins/loadable-model', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    /**
     `load` gives you an explicit way to asynchronously load related data.
      ```js
     post.load('comments');
     ```
      The above uses Storefront's `loadRecord` method to query your backend for the post along with its comments.
      You can also use JSON:API's dot notation to load additional related relationships.
      ```js
     post.load('comments.author');
     ```
      Every call to `load()` will return a promise.
      ```js
     post.load('comments').then(() => console.log('loaded comments!'));
     ```
      If a relationship has never been loaded, the promise will block until the data is loaded. However, if a relationship has already been loaded (even from calls to `loadRecord` elsewhere in your application), the promise will resolve synchronously with the data from Storefront's cache. This means you don't have to worry about overcalling `load()`.
      This feature works best when used on relationships that are defined with `{ async: false }` because it allows `load()` to load the data, and `get()` to access the data that has already been loaded.
      @method load
     @param {String} includesString a JSON:API includes string representing the relationships to check
     @return {Promise} a promise resolving with the record
     @public
    */
    load: function load() {
      var modelName = this.constructor.modelName;

      for (var _len = arguments.length, includes = Array(_len), _key = 0; _key < _len; _key++) {
        includes[_key] = arguments[_key];
      }

      return this.get('store').loadRecord(modelName, this.get('id'), {
        include: includes.join(',')
      });
    },


    /**
      This method returns true if the provided includes string has been loaded and false if not.
       @method hasLoaded
      @param {String} includesString a JSON:API includes string representing the relationships to check
      @return {Boolean} true if the includes has been loaded, false if not
      @public
    */
    hasLoaded: function hasLoaded(includesString) {
      var modelName = this.constructor.modelName;

      return this.get('store').hasLoadedIncludesForRecord(modelName, this.get('id'), includesString);
    }
  });
});