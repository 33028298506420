define('ember-prop-types/extensions/component-prop-types', ['exports', 'ember', 'ember-prop-types/mixins/prop-types', 'ember-prop-types/utils/prop-types'], function (exports, _ember, _emberPropTypesMixinsPropTypes, _emberPropTypesUtilsPropTypes) {
  var Component = _ember['default'].Component;
  var isArray = _ember['default'].isArray;

  /**
   * @module
   */

  /**
   * @memberof ember/Component#
   */
  Component.reopen(_emberPropTypesMixinsPropTypes['default'], {
    init: function init() {
      if (_emberPropTypesMixinsPropTypes.settings.requireComponentPropTypes) {
        var propTypes = this.get('propTypes');

        if (!isArray(propTypes) || propTypes.length === 0) {
          _emberPropTypesUtilsPropTypes.logger.warn(this, 'propTypes is required for components', _emberPropTypesMixinsPropTypes.settings.throwErrors);
        }
      }

      this._super.apply(this, arguments);
    }
  });
});