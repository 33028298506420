define('ember-changeset/utils/is-changeset', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = isChangeset;
  var CHANGESET = exports.CHANGESET = '__CHANGESET__';

  function isChangeset(changeset /*: Object */) {
    return Ember.get(changeset, '__changeset__') === CHANGESET;
  }
});