define('ember-prop-types/initializers/component-prop-types', ['exports', 'ember-prop-types/extensions/component-prop-types'], function (exports, _emberPropTypesExtensionsComponentPropTypes) {
  exports.initialize = initialize;

  /**
   * @module
   */

  /**
   * To load the component-prop-types extensions for Ember.Component
   *
   * @function
   * @returns {undefined}
  */

  function initialize() {}

  /**
   * Export the addon component-prop-types extensions initializer
   *
   * @type {Object}
   */
  exports['default'] = {
    name: 'component-prop-types',
    initialize: initialize
  };
});