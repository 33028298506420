define("ember-keyboard/utils/sort", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.compare = compare;
  _exports.compareProp = compareProp;
  _exports.reverseCompareProp = reverseCompareProp;

  function compare(a, b) {
    var diff = a - b;
    return (diff > 0) - (diff < 0);
  }

  function compareProp(a, b, propName, convertValue) {
    return compare(convertValue ? convertValue(Ember.get(a, propName)) : Ember.get(a, propName), convertValue ? convertValue(Ember.get(b, propName)) : Ember.get(b, propName));
  }

  function reverseCompareProp(a, b, propName) {
    var convertValue = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
    return compareProp(b, a, propName, convertValue);
  }
});