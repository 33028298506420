define('ember-data-storefront/mixins/snapshottable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Mixin.create({

    /*
      Graph for a post looks like
         {
          author: true,
          comments: {
            author: true
          }
        }
       Call `true` to stop at a leaf, pass an object to keep going.
       Snapshots look like
        {
          relationships: {
            comments: [
              {
                model: MODEL,
                relationships: {
                  author: { model: MODEL, relationships: {} }
                }
              }, ...
            ], {
              author: { model: MODEL, relationships: {} }
            }
          }
        }
    */
    takeSnapshot: function takeSnapshot() {
      var _this = this;

      var graph = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var snapshot = { model: this, relationships: {} };

      Object.keys(graph).forEach(function (key) {
        var node = graph[key];
        var relationship = _this.get(key);

        if (Ember.isArray(relationship)) {
          snapshot.relationships[key] = relationship.map(function (model) {
            return { model: model, relationships: {} };
          });
        } else {
          snapshot.relationships[key] = { model: relationship, relationships: {} };
        }

        // call all this recursively instead
        if ((typeof node === 'undefined' ? 'undefined' : _typeof(node)) === 'object') {
          Object.keys(node).forEach(function (subkey) {
            var namedRelationshipMeta = snapshot.relationships[key];
            if (namedRelationshipMeta) {
              if (Ember.isArray(namedRelationshipMeta)) {
                namedRelationshipMeta.forEach(function (relationshipSnapshot) {
                  var nestedRelationship = relationshipSnapshot.model.get(subkey);

                  if (Ember.isArray(nestedRelationship)) {
                    relationshipSnapshot.relationships[subkey] = nestedRelationship.map(function (model) {
                      return { model: model, relationships: {} };
                    });
                  } else {
                    relationshipSnapshot.relationships[subkey] = { model: nestedRelationship, relationships: {} };
                  }

                  // check the node (would be handled by recursive call)
                });
              } else {
                // Deal with object case
                var nestedRelationship = namedRelationshipMeta.model.get(subkey);

                if (Ember.isArray(nestedRelationship)) {
                  namedRelationshipMeta.relationships[subkey] = nestedRelationship.map(function (model) {
                    return { model: model, relationships: {} };
                  });
                } else {
                  namedRelationshipMeta.relationships[subkey] = { model: nestedRelationship, relationships: {} };
                }
              }
            }
          });
        }
      });

      return snapshot;
    },


    /*
      Snapshots look like this:
         {
          model: this,
          relationships: {
            comments: [
              {
                model: MODEL,
                relationships: {
                  author: { model: MODEL, relationships: {} }
                }
              }, ...
            ], {
              author: { model: MODEL, relationships: {} }
            }
          }
        }
       TODO: For now, calling rollbackAttributes on every model we restore. Silly because
      the attributes are not coming from the snapshot. We should use this.serialize to
      store them in a data structure.
    */
    restoreSnapshot: function restoreSnapshot(snapshot) {
      var _this2 = this;

      snapshot.model && snapshot.model.rollbackAttributes();

      Object.keys(snapshot.relationships).forEach(function (key) {
        var relationshipSnapshot = snapshot.relationships[key];
        if (Ember.isArray(relationshipSnapshot)) {
          _this2.set(key, relationshipSnapshot.map(function (meta) {
            return meta.model;
          }));
          relationshipSnapshot.forEach(function (rSnapshot) {
            var model = rSnapshot.model;
            model.rollbackAttributes();
            if (Object.keys(rSnapshot.relationships).length) {
              (false && !(model.restoreSnapshot !== undefined) && Ember.assert('You\'re trying to restore a snapshot on a ' + model._debugContainerKey + ' but that model isn\'t snapshottable. Be sure to include the Snapshottable mixin.', model.restoreSnapshot !== undefined));

              model.restoreSnapshot(rSnapshot);
            }
          });
        } else {
          var model = relationshipSnapshot.model;

          _this2.set(key, model);

          // Model could be null (reverting to null relationship).
          if (model) {
            model.rollbackAttributes();
          }

          if (Object.keys(relationshipSnapshot.relationships).length) {
            (false && !(model.restoreSnapshot !== undefined) && Ember.assert('You\'re trying to restore a snapshot on a ' + model._debugContainerKey + ' but that model isn\'t snapshottable. Be sure to include the Snapshottable mixin.', model.restoreSnapshot !== undefined));

            model.restoreSnapshot(relationshipSnapshot);
          }
        }
      });
    }
  });
});