define('ember-prop-types/utils/validators/func', ['exports', 'ember', 'ember-prop-types/utils/logger'], function (exports, _ember, _emberPropTypesUtilsLogger) {
  var typeOf = _ember['default'].typeOf;

  exports['default'] = function (ctx, name, value, def, logErrors, throwErrors) {
    var valid = typeOf(value) === 'function';

    if (!valid && logErrors) {
      _emberPropTypesUtilsLogger['default'].warn(ctx, 'Expected property ' + name + ' to be a function but instead got: ' + typeOf(value), throwErrors);
    }

    return valid;
  };
});
/**
 * The PropTypes.func validator
 */