define('ember-prop-types/utils/validators/array-of', ['exports', 'ember', 'ember-prop-types/utils/logger'], function (exports, _ember, _emberPropTypesUtilsLogger) {
  var typeOf = _ember['default'].typeOf;
  var isArray = Array.isArray;

  exports['default'] = function (validators, ctx, name, value, def, logErrors, throwErrors) {
    var typeDef = def.typeDef;

    var valid = isArray(value) && value.every(function (item, index) {
      return validators[typeDef.type](ctx, name + '[' + index + ']', item, typeDef, logErrors, throwErrors);
    });

    if (!valid && logErrors) {
      _emberPropTypesUtilsLogger['default'].warn(ctx, 'Expected property ' + name + ' to be an array of type ' + typeDef.type + ' but instead got: ' + typeOf(value), throwErrors);
    }

    return valid;
  };
});
/**
 * The PropTypes.arrayOf validator
 */