define('ember-prop-types/index', ['exports', 'ember-prop-types/mixins/prop-types'], function (exports, _emberPropTypesMixinsPropTypes) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberPropTypesMixinsPropTypes['default'];
    }
  });
  Object.defineProperty(exports, 'helpers', {
    enumerable: true,
    get: function get() {
      return _emberPropTypesMixinsPropTypes.helpers;
    }
  });
  Object.defineProperty(exports, 'PropTypes', {
    enumerable: true,
    get: function get() {
      return _emberPropTypesMixinsPropTypes.PropTypes;
    }
  });
});