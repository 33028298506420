define('ember-data-storefront/-private/record-query', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var RecordQuery = function () {
    function RecordQuery(store, type, id, params) {
      _classCallCheck(this, RecordQuery);

      this.store = store;
      this.type = type;
      this.id = id;
      this.params = params;

      this.value = null;
    }

    _createClass(RecordQuery, [{
      key: 'run',
      value: function run() {
        var _this = this;

        // If the query has params, we force a reload, since Ember Data treats all
        // findRecords the same.
        var hasParams = Object.keys(this.params).length > 0;
        var options = Ember.assign({ reload: hasParams }, this.params);

        return this.store.findRecord(this.type, this.id, options).then(function (record) {
          _this.value = record;

          return record;
        });
      }
    }]);

    return RecordQuery;
  }();

  exports.default = RecordQuery;
});