define('ember-prop-types/utils/validators/bool', ['exports', 'ember', 'ember-prop-types/utils/logger'], function (exports, _ember, _emberPropTypesUtilsLogger) {
  var typeOf = _ember['default'].typeOf;

  exports['default'] = function (ctx, name, value, def, logErrors, throwErrors) {
    var valid = typeOf(value) === 'boolean';

    if (!valid && logErrors) {
      _emberPropTypesUtilsLogger['default'].warn(ctx, 'Expected property ' + name + ' to be a boolean but instead got: ' + typeOf(value), throwErrors);
    }

    return valid;
  };
});
/**
 * The PropTypes.bool validator
 */