define('ember-data-storefront/instance-initializers/mixin-storefront', ['exports', 'ember-data-storefront/mixins/loadable-store'], function (exports, _loadableStore) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(appInstance) {
    var store = appInstance.lookup('service:store');
    store.reopen(_loadableStore.default);
    store.resetCache();
  }

  exports.default = {
    name: 'mixin-storefront',
    after: 'ember-data',
    initialize: initialize
  };
});