define('ember-data-storefront/services/storefront', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),

    fastbootDataRequests: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('fastbootDataRequests', {});
    },
    findAll: function findAll() {
      var _get;

      Ember.deprecate('The storefront service has been deprecated, please use store.loadAll instead. Will be removed in 1.0.', false, { id: 'ember-data-storefront.storefront-find-all', until: '1.0.0' });

      return (_get = this.get('store')).loadAll.apply(_get, arguments);
    },
    loadAll: function loadAll() {
      var _get2;

      Ember.deprecate('The storefront service has been deprecated, please use store.loadAll instead. Will be removed in 1.0.', false, { id: 'ember-data-storefront.storefront-load-all', until: '1.0.0' });

      return (_get2 = this.get('store')).loadAll.apply(_get2, arguments);
    },
    findRecord: function findRecord() {
      var _get3;

      Ember.deprecate('The storefront service has been deprecated, please use store.loadRecord instead. Will be removed in 1.0.', false, { id: 'ember-data-storefront.storefront-find-record', until: '1.0.0' });

      return (_get3 = this.get('store')).findRecord.apply(_get3, arguments);
    },
    loadRecord: function loadRecord() {
      var _get4;

      Ember.deprecate('The storefront service has been deprecated, please use store.loadRecord instead. Will be removed in 1.0.', false, { id: 'ember-data-storefront.storefront-load-record', until: '1.0.0' });

      return (_get4 = this.get('store')).findRecord.apply(_get4, arguments);
    },
    hasLoadedIncludesForRecord: function hasLoadedIncludesForRecord() {
      var _get5;

      Ember.deprecate('The storefront service has been deprecated, please use store.hasLoadedIncludesForRecord instead. Will be removed in 1.0.', false, { id: 'ember-data-storefront.storefront-has-loaded-includes-for-record', until: '1.0.0' });

      return (_get5 = this.get('store')).hasLoadedIncludesForRecord.apply(_get5, arguments);
    },
    resetCache: function resetCache() {
      var _get6;

      Ember.deprecate('The storefront service has been deprecated, please use store.resetCache instead. Will be removed in 1.0.', false, { id: 'ember-data-storefront.storefront-reset-cache', until: '1.0.0' });

      return (_get6 = this.get('store')).resetCache.apply(_get6, arguments);
    }
  });
});