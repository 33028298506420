define("ember-keyboard/mixins/activate-keyboard-on-focus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    init: function init() {
      (false && !(false) && Ember.deprecate('`EKOnFocusMixin` of ember-keyboard is deprecated. This behavior is now automatic when using the `on-key` modifier with a text field. For other use cases, implement manually.', false, {
        id: 'ember-keyboard.activate-keyboard-on-focus-mixin',
        until: '7.0.0',
        url: 'https://adopted-ember-addons.github.io/ember-keyboard/deprecations#activate-keyboard-on-focus-mixin'
      }));
      return this._super.apply(this, arguments);
    },
    activateKeyboardWhenFocused: Ember.on('click', 'focusIn', function () {
      Ember.set(this, 'keyboardActivated', true);
    }),
    deactivateKeyboardWhenFocusOut: Ember.on('focusOut', function () {
      Ember.set(this, 'keyboardActivated', false);
    })
  });

  _exports.default = _default;
});