define("ember-component-css/pod-names", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "canny-changelog-trigger": "__82d37",
    "external-service-icon": "__46202",
    "fetchable-image": "__7ebf0",
    "loading-spinner-modal": "__ee0d4",
    "select-input": "__73603",
    "settings-layout": "__2e756",
    "simple-dropdown/content": "__4579e",
    "status-badge": "__65389",
    "text-input": "__afc33"
  };
  _exports.default = _default;
});