define('ember-data-storefront/mixins/loadable', ['exports', 'ember-data-storefront/mixins/loadable-model'], function (exports, _loadableModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_loadableModel.default, {

    showDeprecations: Ember.on('init', function () {
      Ember.deprecate('The Loadable mixin has been renamed to LoadableMixin. Please change all instances of Loadable in your app to LoadableMixin. Loadable will be removed in 1.0.', false, { id: 'ember-data-storefront.loadable', until: '1.0.0' });
    })

  });
});