define('ember-data-storefront/mixins/fastboot-adapter', ['exports', 'ember-data-storefront/-private/utils/get-key'], function (exports, _getKey) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    fastboot: Ember.inject.service(),
    storefront: Ember.inject.service(),

    ajax: function ajax(url, type) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      var cachedPayload = this._getStorefrontBoxedQuery(type, url, options.data);
      var maybeAddToShoebox = this._makeStorefrontQueryBoxer(type, url, options.data);

      return cachedPayload ? Ember.RSVP.resolve(JSON.parse(cachedPayload)) : this._super.apply(this, arguments).then(maybeAddToShoebox);
    },
    _makeStorefrontQueryBoxer: function _makeStorefrontQueryBoxer(type, url, params) {
      var fastboot = this.get('fastboot');
      var isFastboot = fastboot && fastboot.get('isFastBoot');
      var cache = this.get('storefront.fastbootDataRequests');

      return function (response) {
        if (isFastboot) {
          var key = (0, _getKey.shoeboxize)((0, _getKey.cacheKey)([type, url, params]));
          cache[key] = JSON.stringify(response);
        }

        return response;
      };
    },
    _getStorefrontBoxedQuery: function _getStorefrontBoxedQuery(type, url, params) {
      var payload = void 0;
      var fastboot = this.get('fastboot');
      var isFastboot = fastboot && fastboot.get('isFastBoot');
      var shoebox = fastboot && fastboot.get('shoebox');
      var box = shoebox && shoebox.retrieve('ember-data-storefront');

      if (!isFastboot && box && box.queries && Object.keys(box.queries).length > 0) {
        var key = (0, _getKey.shoeboxize)((0, _getKey.cacheKey)([type, url, params]));
        payload = box.queries[key];
        delete box.queries[key];
      }

      return payload;
    }
  });
});