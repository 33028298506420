define('ember-data-storefront/instance-initializers/inject-storefront', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(appInstance) {
    appInstance.inject('route', 'storefront', 'service:storefront');
    appInstance.inject('controller', 'storefront', 'service:storefront');
  }

  exports.default = {
    name: 'inject-storefront',
    after: 'mixin-storefront',
    initialize: initialize
  };
});