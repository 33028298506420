define('ember-data-storefront/components/assert-must-preload/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  exports.default = Ember.Component.extend({
    tagName: '',

    didReceiveAttrs: function didReceiveAttrs() {
      var _get = this.get('args'),
          _get2 = _toArray(_get),
          model = _get2[0],
          includes = _get2.slice(1);

      var parentComponent = this.parentView;
      var parentName = parentComponent ? parentComponent._debugContainerKey : 'template';
      var includesString = includes.join(',');

      (false && !(model.hasLoaded) && Ember.assert('You passed a ' + model.constructor.modelName + ' model into an {{assert-must-preload}}, but that model is not using the Loadable mixin. [ember-data-storefront]', model.hasLoaded));
      (false && !(model.hasLoaded(includesString)) && Ember.assert('You tried to render a ' + parentName + ' that accesses relationships off of a ' + model.constructor.modelName + ', but that model didn\'t have all of its required relationships preloaded (\'' + includesString + '\'). Please make sure to preload the association. [ember-data-storefront]', model.hasLoaded(includesString)));


      return this._super.apply(this, arguments);
    }
  }).reopenClass({

    positionalParams: 'args'

  });
});