define('ember-prop-types/utils/logger', ['exports', 'ember'], function (exports, _ember) {
  var Logger = _ember['default'].Logger;
  exports['default'] = {
    throwError: function throwError(message) {
      throw new Error(message);
    },

    /**
     * Log a warning message
     * @param {*} obj - the object doing the warning
     * @param {String} message - the warning
     * @param {Boolean} throwError - whether or not to throw error
     */
    warn: function warn(obj, message, throwError) {
      var id = obj.toString();
      message = '[' + id + ']: ' + message;

      if (throwError) {
        this.throwError(message);
      } else {
        Logger.warn(message);
      }
    }
  };
});
/**
 * Logging utility that includes a little information about the object doing the logging
 */