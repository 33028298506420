define('ember-data-storefront/-private/cache', ['exports', 'ember-data-storefront/-private/utils/get-key'], function (exports, _getKey) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var Cache = function () {
    function Cache() {
      _classCallCheck(this, Cache);

      this.store = {};
    }

    _createClass(Cache, [{
      key: 'get',
      value: function get() {
        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        var key = (0, _getKey.cacheKey)(args);
        return this.store[key];
      }
    }, {
      key: 'put',
      value: function put(query) {
        var key = (0, _getKey.queryCacheKey)(query);
        this.store[key] = query;
        return query;
      }
    }, {
      key: 'all',
      value: function all() {
        var _this = this;

        return Object.keys(this.store).map(function (key) {
          return _this.store[key];
        });
      }
    }]);

    return Cache;
  }();

  exports.default = Cache;
});